<template>
  <v-container class="mx-0 pt-5 px-0">
    <v-row align="center" justify="space-between" no-gutters>
      <v-col class="col-8">
        <span class="title font-weight-medium">{{ $t('custom.input.title') }}</span>
      </v-col>

      <v-col class="col-auto">
        <v-btn color="primary" text small @click="onOpenAddDialog">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('general.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" no-gutters>
      <v-col v-if="hasCustomInputsValues" class="col-12 text-right">
        <v-row
          v-for="customInput in contactCustomInputsValues"
          :key="customInput.id"
          align="center"
          justify="center"
          no-gutters
        >
          <v-col class="col-4 subtitle-2 font-weight-medium grey--text text-left text-break py-1">
            {{ customInput.workspaceCustomInputName }}
          </v-col>

          <v-col class="col-4 subtitle-2 font-weight-medium black--text text-left text-break py-1 px-2">
            {{ customInput.value }}
          </v-col>

          <v-col class="col-4">
            <v-btn icon small @click="onOpenEditDialog(customInput)">
              <v-icon small>mdi-pencil-outline</v-icon>
            </v-btn>

            <v-btn icon small @click="onDeleteDialog(customInput)">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-else class="col-12 pt-2">
        <span class="subtitle-2">{{ $t('table.noResults') }}...</span>
      </v-col>
    </v-row>

    <AddContactCustomInputValue
      v-if="openAddInputValueDialog"
      v-model="openAddInputValueDialog"
      :contact-id="contactId"
      :workspace-id="workspaceId"
      @on-reload="fetchCustomInputs"
    />

    <EditContactCustomInputValue
      v-if="openEditInputValueDialog"
      v-model="openEditInputValueDialog"
      :contact-id="contactId"
      :workspace-id="workspaceId"
      :selected-custom-input="selectedCustomInput"
      @on-reload="fetchCustomInputs"
    />

    <SConfirmDialog
      v-model="openDeleteInputValueDialog"
      :message="`${$t('custom.input.delete')}`"
      @confirmation-callback="onDeleteInputValue"
    />
  </v-container>
</template>

<script>
import { defineComponent } from 'vue'
import { useContactCustomInputs } from '@/features/contacts/stores/customInputs'
import AddContactCustomInputValue from '@/features/contacts/components/AddContactCustomInputValue'
import EditContactCustomInputValue from '@/features/contacts/components/EditContactCustomInputValue'
import SConfirmDialog from '@/common/components/SConfirmDialog'

export default defineComponent({
  name: 'ContactCustomInputs',
  components: {
    AddContactCustomInputValue,
    EditContactCustomInputValue,
    SConfirmDialog,
  },
  props: {
    contactId: {
      type: [Number, String],
      required: true,
    },
    workspaceId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const contactCustomInputsStore = useContactCustomInputs()

    return {
      contactCustomInputsStore,
    }
  },
  data() {
    return {
      openAddInputValueDialog: false,
      openEditInputValueDialog: false,
      openDeleteInputValueDialog: false,
      selectedCustomInput: null,
    }
  },
  computed: {
    contactCustomInputsValues() {
      return this.contactCustomInputsStore.contactCustomInputsValues
    },
    hasCustomInputsValues() {
      return !!this.contactCustomInputsValues?.length
    },
  },
  mounted() {
    this.fetchCustomInputs()
  },
  methods: {
    async fetchCustomInputs() {
      await this.contactCustomInputsStore.resetCustomInputs()
      await this.contactCustomInputsStore.fetchWorkspaceCustomInputs(this.workspaceId)
      await this.contactCustomInputsStore.fetchContactCustomInputs(this.workspaceId, this.contactId)
    },
    onOpenAddDialog() {
      this.openAddInputValueDialog = true
    },
    onOpenEditDialog(customInput) {
      this.openEditInputValueDialog = true
      this.selectedCustomInput = Object.assign({}, customInput)
    },
    onDeleteDialog(customInput) {
      this.openDeleteInputValueDialog = true
      this.selectedCustomInput = Object.assign({}, customInput)
    },
    onDeleteInputValue() {
      this.contactCustomInputsStore
        .updateCustomInputValue({
          workspaceId: this.workspaceId,
          customInputId: this.selectedCustomInput?.workspaceCustomInputId,
          customInputPayload: {
            contactId: this.selectedCustomInput?.contactId,
            value: null,
          },
        })
        .finally(() => this.fetchCustomInputs())
    },
    onCloseDialog() {
      this.selectedCustomInput = null
    },
  },
})
</script>

<style lang="scss" scoped>
.custom-input__head {
}

.custom-input__value {
}
</style>
