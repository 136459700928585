<template>
  <v-dialog :value="showDialog" transition="dialog-bottom-transition" max-width="600">
    <v-card min-height="275">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-title class="pt-0 mt-0">
        <h2 class="mx-auto mb-0">{{ $t('custom.input.title') }}</h2>
      </v-card-title>

      <v-card-text class="pa-5">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-6">
            <v-text-field
              v-model="customInputName"
              id="newCustomInputName"
              name="newCustomInputName"
              list="customInputName"
              :placeholder="$t('general.title')"
              :label="$t('general.title')"
              hide-details
              outlined
            ></v-text-field>

            <datalist id="customInputName">
              <option
                v-for="workspacePredefinedCustomInput in workspacePredefinedCustomInputs"
                :key="workspacePredefinedCustomInput.id"
                :value="workspacePredefinedCustomInput.name"
              />
            </datalist>
          </v-col>

          <v-col class="col-12 col-md-6">
            <v-text-field
              v-model="customInputValue"
              id="new-custom-input-value"
              :placeholder="$t('general.value')"
              :label="$t('general.value')"
              hide-details
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mt-0 pt-0">
        <v-row align="center" justify="center">
          <v-col class="col-12 col-md-6" order="6" order-md="0">
            <v-btn block @click="showDialog = false">
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="col-12 col-md-6">
            <v-btn class="primary" :loading="isSavingData" block @click="onAddCustomInputValue">
              {{ $t('general.create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { useContactCustomInputs } from '@/features/contacts/stores/customInputs'

export default defineComponent({
  name: 'AddContactCustomInputValue',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    contactId: {
      type: [Number, String],
      required: true,
    },
    workspaceId: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const contactCustomInputsStore = useContactCustomInputs()

    return {
      contactCustomInputsStore,
    }
  },
  data() {
    return {
      customInputName: '',
      customInputValue: '',
      savingData: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    workspacePredefinedCustomInputs() {
      return this.contactCustomInputsStore.workspaceCustomInputs
    },
    isSavingData() {
      return this.savingData
    },
  },
  methods: {
    onAddCustomInputValue() {
      this.savingData = true

      const currentWorkspaceInput = this.workspacePredefinedCustomInputs?.find(
        (workspaceInput) => workspaceInput.name === this.customInputName,
      )

      if (currentWorkspaceInput) {
        this.contactCustomInputsStore
          .updateCustomInputValue({
            workspaceId: this.workspaceId,
            customInputId: currentWorkspaceInput.id,
            customInputPayload: {
              value: this.customInputValue,
              contactId: this.contactId,
            },
          })
          .finally(() => {
            this.savingData = false
            this.showDialog = false
            this.$emit('on-reload')
          })
      } else {
        this.contactCustomInputsStore
          .createCustomInput(this.workspaceId, this.customInputName)
          .then((resp) => {
            this.contactCustomInputsStore.updateCustomInputValue({
              workspaceId: this.workspaceId,
              customInputId: resp.id,
              customInputPayload: {
                value: this.customInputValue,
                contactId: this.contactId,
              },
            })
          })
          .finally(() => {
            this.savingData = false
            this.showDialog = false
            this.$emit('on-reload')
          })
      }
    },
  },
})
</script>

<style lang="scss" scoped></style>
