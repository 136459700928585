<template>
  <div class="dashboard-group">
    <div class="dashboard-group-item">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardGroup',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-group {
  display: flex;
  margin: 1px;
  margin-left: 0 !important;
  float: left;

  &-item {
    overflow: auto;
    color: #82e2b2;
    border: 1px solid #82e2b2;
    border-radius: 6px;
    font-size: small;
    padding: 2px 5px;
    margin: {
      top: 1px;
      right: 2px;
      bottom: 1px;
      left: 0;
    }
  }
}
</style>
