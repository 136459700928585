<template>
  <Modal :enableClose="true" @close="$emit('close')">
    <div class="groups-modal">
      <h1>{{ $t('groups.adjustment') }}<br />{{ modalTitle }}</h1>

      <div v-if="isLoaded" class="groups-modal__groups-list-wrapper">
        <div
          v-for="(group, key) in groups.state"
          :key="key"
          class="groups-modal__groups-list-row"
          :class="{ 'groups-modal__groups-list-row-editing': updatedGroup && group.id === updatedGroup.id }"
        >
          <div v-if="updatedGroup && group.id === updatedGroup.id">
            <form @submit.prevent="renameGroup(group)" class="group-list-item__row-edit">
              <Input
                v-model="group.name"
                class="group-list-item__row-column group-list-item__row-column-input-rename"
                :marginTopReset="true"
                :placeholder="$t(`placeholder.group`)"
              />
              <Button
                button-type="submit"
                class="group-list-item__row-column group-list-item__row-column-button-rename"
                type="primary"
                >{{ $t('general.save') }}</Button
              >
            </form>
          </div>
          <div v-else class="group-list-item__row-actions">
            <div class="group-list-item__row-column">
              <DashboardGroup :title="group.name" />
            </div>
            <div class="group-list-item__row-column">
              <a class="" href="#" @click.prevent="updatedGroup = Object.assign({}, group)">{{
                $t('general.rename')
              }}</a>
            </div>
            <div class="group-list-item__row-column">
              <a class="" href="#" @click.prevent="deleteContactGroup(group)">{{ $t('groups.delete_from_contact') }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="contacts-modal__new-group">
        <p>{{ $t('contacts.groups.add_info') }}</p>

        <form @submit.prevent="addContactGroup()" class="groups-modal__add-group">
          <Input
            v-model="newGroupName"
            class="groups-modal__add-group-input"
            :marginTopReset="true"
            :placeholder="$t(`placeholder.group`)"
            dataListId="availableGroups"
          />
          <datalist id="availableGroups">
            <option v-for="(availableGroup, index) in availableGroups" :key="index" :value="availableGroup.name" />
          </datalist>
          <v-btn
            class="groups-modal__add-group-button"
            height="3.5rem"
            x-large
            outlined
            :disabled="!newGroupName"
            type="submit"
            :block="$vuetify.breakpoint.mobile"
          >
            {{ $t('general.add') }}
          </v-btn>
        </form>

        <div class="modal-tip">
          <span class="modal-tip-icon">
            <IcoInfo />
          </span>
          {{ $t('groups.info') }}
        </div>
      </div>

      <div class="contacts-modal__actions">
        <Button class="contacts-modal__actions-cancel" @click="$emit('close')">
          {{ $t('general.cancel') }}
        </Button>
        <Button class="contacts-modal__actions-submit" type="primary" @click="updateContactGroups">
          {{ $t('general.save') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { ContactService } from '@/services/ContactService.js'
import Modal from '@/components/Modal'
import DashboardGroup from '@/views/dashboard/components/DashboardGroup'
import IcoInfo from '@/components/svg/IcoInfo'

export default {
  name: 'GroupsModal',
  components: {
    DashboardGroup,
    IcoInfo,
    Modal,
  },
  data() {
    return {
      isLoaded: false,
      groups: {
        state: [],
        toAdd: [],
        toRename: [],
      },
      newGroupName: '',
      updatedGroup: null,
    }
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isMobileDevice: 'window/isMobileDevice',
      dashboardAvailableGroups: 'dashboardAvailableGroups',
    }),
    modalTitle() {
      return this.contacts.length > 1
        ? this.$t('groups.adjustment_more', { count: this.contacts.length })
        : this.$t('groups.adjustment_one', { contact: `${this.contacts[0].fullname}` })
    },
    groupsState() {
      let groups = [...this.groups.state, ...this.groups.toAdd]
      this.contacts.forEach((contact) => {
        contact.groups.forEach((group) => {
          let isNotGroupIn = true
          groups.forEach((stateGroup) => {
            if (group.name === stateGroup.name) {
              isNotGroupIn = false
            }
          })
          if (isNotGroupIn) {
            groups.push(group)
          }
        })
      })

      return groups
    },
    availableGroups() {
      let availableGroups = []
      this.dashboardAvailableGroups.forEach((group) => {
        let isNotGroupIn = true
        this.groups.state.forEach((stateGroup) => {
          if (group.name === stateGroup.name) {
            isNotGroupIn = false
          }
        })
        if (isNotGroupIn) {
          availableGroups.push(group)
        }
      })
      return availableGroups
    },
  },
  async created() {
    await this.groupsState.forEach((group) => {
      this.groups.state.push(Object.assign({}, group))
    })
    this.isLoaded = true
  },
  methods: {
    addContactGroup() {
      const groupToAdd = { name: this.newGroupName }
      let groupNotExist = true
      this.availableGroups.forEach((group) => {
        if (group.name === groupToAdd.name) {
          this.groups.state.push(group)
          groupNotExist = false
        }
      })
      if (groupNotExist) {
        this.groups.state.push(groupToAdd)
        this.groups.toAdd.push(groupToAdd)
      }
      this.newGroupName = ''
    },
    renameGroup(groupToUpdate) {
      let alreadyUpdatedGroup = this.groups.toRename.find((g) => g.id === this.updatedGroup.id)

      if (
        'id' in groupToUpdate &&
        typeof alreadyUpdatedGroup === 'undefined' &&
        this.updatedGroup.name &&
        groupToUpdate.name !== this.updatedGroup.name
      ) {
        this.groups.toRename.push(groupToUpdate)
      }

      this.updatedGroup = null
    },
    deleteContactGroup(group) {
      let groupIndex = this.groups.state.indexOf(group)
      if (groupIndex > -1) {
        this.groups.state.splice(groupIndex, 1)
      }

      groupIndex = this.groups.toAdd.indexOf(group)
      if (groupIndex > -1) {
        this.groups.toAdd.splice(groupIndex, 1)
      }
    },
    async updateContactGroups() {
      try {
        if (this.groups.toAdd.length) {
          await ContactService.manageContacts({
            workspace_id: this.$route.params.workspace_id,
            action: 'upsertGroups',
            groups: this.groups.toAdd,
          })
        }
        if (this.groups.toRename.length) {
          await ContactService.manageContacts({
            workspace_id: this.$route.params.workspace_id,
            action: 'upsertGroups',
            groups: this.groups.toRename,
          })
        }
        await ContactService.manageContacts({
          workspace_id: this.$route.params.workspace_id,
          action: 'replaceContactsGroups',
          contacts: [...this.contacts],
          groups: [...this.groups.state, ...this.groups.toAdd],
        })
        this.$emit('reloadAfterChangingGroups')
      } catch (e) {
        this.$notification.error(this.$t('contract.errors.general_save'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.groups-modal {
  padding: 0 10%;

  p {
    text-align: left;
    font-weight: normal;
  }
}

.group-list-item__row-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.group-list-item__row-column-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.groups-modal__groups-list-wrapper {
  margin-bottom: 20px;
  border-top: 1px solid #e8edf5;

  .groups-modal__groups-list-row {
    border-bottom: 1px solid #e8edf5;
    padding: {
      top: 20px;
      bottom: 20px;
    }

    &-editing {
      padding: {
        top: 75px;
        bottom: 75px;
      }
    }

    .group-list-item__row-actions {
      @extend .group-list-item__row-root;

      .group-list-item__row-column {
        @extend .group-list-item__row-column-root;

        & > a {
          color: #7b7e83;
          font-size: initial;
          font-weight: initial;
        }

        &:first-child {
          text-align: left;
          flex: 1;
        }

        &:last-child {
          flex: 1;
          & > * {
            text-align: right;
          }
        }

        @include sm {
          padding: {
            top: 5px;
            bottom: 5px;
          }
        }
      }

      @include sm {
        flex-direction: column;
      }
    }

    .group-list-item__row-edit {
      @extend .group-list-item__row-root;

      .group-list-item__row-column {
        &-input-rename {
          flex: 1;
          margin: 0 !important;
          margin-right: 10px !important;
          padding-left: 0 !important;

          @include sm {
            margin-right: 0 !important;
          }
        }

        &-button-rename {
          flex: 1;
          width: 100% !important;
          margin: 0;
          min-width: unset;

          @include sm {
            margin: 10px 5px;
            min-height: 50px !important;
            max-width: 200px;
          }
        }
      }

      @include sm {
        flex-direction: column;
      }
    }
  }
}

.groups-modal__add-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-input {
    flex: 50%;
    margin: 0;
    margin-right: 10px !important;
  }

  &-button {
    flex: 50%;
    margin: 0;
    color: #5d2a9c;
    border: 1px solid #979797;
    background: unset !important;
  }

  @include md {
    display: unset;
    flex-direction: column;
    & > * {
      flex: 100%;
      margin: 10px 0 !important;
      min-height: 50px;
    }
  }
}

.modal-tip {
  margin: 20px 0 !important;
  text-align: left;
  max-width: unset;

  &-icon {
    position: relative;
    top: 2px;
  }
}

.contacts-modal__actions {
  display: flex;
  flex-direction: row;

  &-item-cancel {
    flex: 30%;
  }

  &-item-submit {
    flex: 70%;
  }

  .button {
    margin: 20px 5px;
    width: 100% !important;
    min-width: unset;
  }
  .button:first-child {
    margin-left: unset;
    flex: 50%;
    color: #5d2a9c;
    border: 1px solid #979797;
    background: unset !important;
  }
  .button:last-child {
    margin-right: unset;
  }

  @include md {
    flex-direction: column;
    & > button {
      //flex: 100%;
      margin: 10px 0 !important;
      min-height: 50px;
    }
  }
}

.dashboard-group {
  margin: 4px 0 !important;

  &-item {
    margin: 0 !important;
  }
}
</style>
