import { defineStore } from 'pinia'
import { internalApiRequest } from '@/shared/services/apiService'

export const useContactCustomInputs = defineStore('contactCustomInputs', {
  state: () => ({
    contactValues: [],
    workspaceInputs: [],
  }),
  getters: {
    contactCustomInputsValues: (state) => {
      return state.contactValues
    },
    workspaceCustomInputs: (state) => {
      return state.workspaceInputs?.filter((workspaceInput) => {
        return !state.contactValues?.find(
          (contactValue) => contactValue?.workspaceCustomInputName === workspaceInput?.name,
        )
      })
    },
  },
  actions: {
    fetchContactCustomInputs(workspaceId, contactId) {
      return internalApiRequest({
        method: 'GET',
        path: `api/v1/workspaces/${workspaceId}/contacts/${contactId}`,
      }).then((resp) => {
        this.contactValues = [...resp?.workspaceCustomInputValues]
      })
    },
    fetchWorkspaceCustomInputs(workspaceId) {
      return internalApiRequest({
        method: 'GET',
        path: `api/v2/workspace/${workspaceId}/customInputs`,
      }).then((resp) => {
        this.workspaceInputs = [...resp?.filter((workspaceInput) => 'contact' === workspaceInput?.type)]
      })
    },
    resetCustomInputs() {
      return new Promise((resolve) => {
        this.contactValues = []
        this.workspaceInputs = []
        resolve()
      })
    },
    createCustomInput(workspaceId, name) {
      return internalApiRequest({
        method: 'POST',
        path: `api/v2/workspace/${workspaceId}/customInput`,
        data: {
          type: 'contact',
          name,
        },
      }).then((resp) => {
        this.workspaceInputs.push(resp)

        return resp
      })
    },
    updateCustomInputValue({ workspaceId, customInputId, customInputPayload }) {
      return internalApiRequest({
        method: 'PATCH',
        path: `api/v2/workspace/${workspaceId}/customInput/${customInputId}/value`,
        data: { ...customInputPayload },
      })
    },
  },
})
