<template>
  <Data :loader="isLoadingFirstRequest" :isDashboard="true">
    <div class="contacts-detail__back-button">
      <a href="" @click.prevent="$router.push({ name: `contacts` })">
        <IcoArrowBack />
        {{ $t('contacts.detail.back') }}
      </a>
    </div>

    <div v-if="contact" class="contacts-detail">
      <v-row class="contacts-detail__head-wrapper pt-3 mx-4" align="center" justify="start">
        <v-col cols="auto">
          <VerificationBadge :contact="contact" />
        </v-col>

        <v-col>
          <div class="contacts-detail__head-info-name">
            <h2>{{ contact.fullname }}, {{ contact.organization_name }}</h2>
          </div>
          <div class="contacts-detail__head-info-groups">
            <span v-for="group in contact.groups" :key="group.id" class="contacts-detail__head-info-groups-item">
              {{ group.name }}
            </span>

            <a
              href="#"
              @click.prevent="openModal(`openGroupsModal`, [contact])"
              class="contacts-detail__head-info-groups-item-edit"
            >
              {{ $t('groups.edit') }}
            </a>
          </div>
        </v-col>

        <v-col v-if="contact.has_aml_verified" class="pa-0 text-right">
          <v-menu left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item class="pa-0">
                <v-list-item-content class="pa-0">
                  <v-btn color="primary" min-width="12rem" text @click="dialog = true">
                    <v-icon class="pr-2">mdi-close</v-icon>
                    {{ $t('verification.cancel') }}
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <div class="contacts-detail__wrapper">
        <div class="contacts-detail__wrapper-item">
          <v-row class="pb-3" align="center" justify="space-between" no-gutters>
            <v-col class="col-12 col-md-auto">
              <span class="title font-weight-medium">
                {{ $t('docs.proposer') }}
              </span>
            </v-col>

            <v-col v-if="contactDocuments.length" class="col-12 col-md-auto">
              <v-btn color="primary" outlined @click="sendDocumentToContact">
                {{ $t('contacts.send_simple') }}
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="contactDocuments.length">
            <div class="contacts-detail__documents" :class="{ 'contacts-detail__documents-full': isShowAllDocuments }">
              <div class="contacts-detail__documents-document" v-for="document in contactDocuments" :key="document.id">
                <div class="contacts-detail__documents-document-column">
                  <IcoEmpty />
                </div>
                <div class="contacts-detail__documents-document-column">
                  <div class="contacts-detail__title">
                    <h3
                      style="cursor: pointer"
                      @click="
                        $router.push({
                          name: 'documentsDetail',
                          params: {
                            workspace_id: $route.params.workspace_id,
                            contract_id: document.id,
                          },
                        })
                      "
                    >
                      {{ document.title }}
                    </h3>
                  </div>
                  <DocumentState :state-type="document.state_dashboard" />
                  <div class="contacts-detail__documents-document-column-row">
                    {{ $t('general.creator') }}:
                    {{ document.creator }}
                  </div>
                  <div class="contacts-detail__documents-document-column-row">
                    {{ $t('general.created_at') }}:
                    {{ getDocumentCreatedDate(document.dates.created) }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isShowAllDocuments" class="contacts-detail__document-overflow"></div>

            <div
              v-if="
                (!isMobileDevice && contactDocuments.length >= 4) || (isMobileDevice && contactDocuments.length >= 3)
              "
              class="contacts-detail__documents-show-all-button"
            >
              <a v-if="!isShowAllDocuments" href="#" @click.prevent="showAllDocuments()">
                {{ $t('general.show_all') }}
              </a>
            </div>
          </div>
          <div v-else class="contacts-detail__documents-empty">
            <IcoEmpty />

            <p class="subtitle-2">{{ $t('contacts.no_documents') }}</p>

            <v-btn color="primary" outlined @click="sendDocumentToContact">
              {{ $t('contacts.send_simple') }}
            </v-btn>
          </div>
        </div>

        <div class="contacts-detail__wrapper-item">
          <v-container class="pa-0 ma-0">
            <v-row align="center" justify="space-between" no-gutters>
              <v-col class="col-8">
                <span class="title font-weight-medium">{{ $t('contacts.data') }}</span>
              </v-col>

              <v-col class="col-auto">
                <v-btn color="primary" text small @click="openModalWithRow('upsertIdentity', contact)">
                  <v-icon left>mdi-pencil-outline</v-icon>
                  {{ $t('general.edit') }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="contacts-detail__contact-row">
              <div class="contacts-detail__contact-row-item-left subtitle-2">{{ $t('company.title2') }}:</div>
              <div class="contacts-detail__contact-row-item-right subtitle-2">
                {{ contact.organization_name }}
              </div>
            </div>

            <div class="contacts-detail__contact-row">
              <div class="contacts-detail__contact-row-item-left subtitle-2">{{ $t('company.address') }}:</div>
              <div class="contacts-detail__contact-row-item-right subtitle-2">
                {{ contact.organization_street }}<br />
                {{ contact.organization_city }} {{ contact.organization_zip }}
              </div>
            </div>

            <div class="contacts-detail__contact-row">
              <div class="contacts-detail__contact-row-item-left subtitle-2">{{ $t('company.ico') }}:</div>
              <div class="contacts-detail__contact-row-item-right subtitle-2">
                {{ contact.organization_ico }}
              </div>
            </div>

            <div class="contacts-detail__contact-row">
              <div class="contacts-detail__contact-row-item-left subtitle-2">{{ $t('general.email_simple') }}:</div>
              <div class="contacts-detail__contact-row-item-right email subtitle-2">
                <a :href="`mailto:` + contact.email">
                  {{ contact.email }}
                </a>
              </div>
            </div>

            <div class="contacts-detail__contact-row">
              <div class="contacts-detail__contact-row-item-left subtitle-2">{{ $t('general.phone') }}:</div>
              <div class="contacts-detail__contact-row-item-right phone subtitle-2">
                <a :href="`tel:` + contact.mobile">
                  {{ contact.mobile }}
                </a>
              </div>
            </div>
          </v-container>

          <ContactCustomInputs :contact-id="contact.id" :workspace-id="$route.params.workspace_id" />
        </div>
      </div>
    </div>

    <GroupsModal
      v-if="dashboardModal && dashboardModal.contactAction && dashboardModal.contactAction === 'openGroupsModal'"
      :contacts="[contact]"
      @reloadAfterChangingGroups="reloadDataAndCloseModal"
      @close="closeModal"
    >
    </GroupsModal>

    <Identity
      v-if="dashboardModal && dashboardModal.contactAction === 'upsertIdentity'"
      class="modal-phone-off"
      :isContactEditor="true"
      :modalConfig="{
        contractId: null,
        editedSignIdentity: dashboardModal.contactsSelected.length ? dashboardModal.contactsSelected[0] : null,
      }"
      @close="closeModal()"
      @upsertIdentity="updateContactFromIdentityModal"
    />

    <SigniModal v-model="dialog" max-width="400px">
      <v-card-title class="pa-0">
        <span class="mx-auto">
          {{ $t('verification.cancel.question') }}
        </span>
      </v-card-title>

      <v-card-actions class="pa-0 pt-5">
        <v-btn color="error" :loading="cancelingVerification" x-large block @click="cancelContactVerification">
          {{ $t('verification.cancel') }}
        </v-btn>
      </v-card-actions>
    </SigniModal>
  </Data>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { ContactService } from '@/services/ContactService'
import { cancelVerification } from '@contacts/services/verificationService'
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import { DataService } from '@/services/DataService'
import IcoArrowBack from '@/components/svg/IcoArrowBack'
import IcoEmpty from '@/views/dashboard/components/icons/IcoEmpty'
import Identity from '@/views/create/components/Identity'
import DocumentState from '@/views/dashboard/components/DocumentState'
import GroupsModal from '@/views/dashboard/components/GroupsModal'
import SigniModal from '@/components/SigniModal'
import VerificationBadge from '@contacts/components/VerificationBadge'
import ContactCustomInputs from '@/features/contacts/components/ContactCustomInputs'

export default defineComponent({
  name: 'ContactDetail',
  components: {
    ContactCustomInputs,
    VerificationBadge,
    SigniModal,
    DocumentState,
    GroupsModal,
    Identity,
    IcoEmpty,
    IcoArrowBack,
  },
  data() {
    return {
      contact: null,
      contactDocuments: [],
      isLoadingFirstRequest: true,
      isShowAllDocuments: false,
      dialog: false,
      cancelingVerification: false,
    }
  },
  computed: {
    ...mapGetters({
      dashboardModal: 'dashboardModal',
      dashboardCheckedDocuments: 'dashboardCheckedDocuments',
    }),
    isMobileDevice() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getDocumentCreatedDate(date) {
      return getFormattedDateByLocale({
        date,
      })
    },
    async fetchData() {
      this.isLoadingFirstRequest = true

      await ContactService.getContacts({
        workspace_id: this.$route.params.workspace_id,
        contact_id: this.$route.params.contact_id,
        load_metadata: true,
      })
        .then((response) => {
          this.$store.commit(
            'dashboardSetAvailableGroups',
            (response.meta.groups || []).map((group) => ({
              name: group.name,
              key: group.id,
              isSelected: false,
            })),
          )

          if (1 === response.data.length) {
            this.contact = response.data[0]
          } else {
            throw new Error('not found')
          }
        })
        .catch(() => {
          return this.$router.push({ name: 'contacts' })
        })

      await DataService.getContracts({
        workspace_id: this.$route.params.workspace_id,
        search_query: this.contact.email,
        states: [],
      }).then((response) => {
        this.contactDocuments = response.data
      })

      this.isLoadingFirstRequest = false
    },
    showAllDocuments() {
      this.isShowAllDocuments = !this.isShowAllDocuments
    },
    openModalWithRow(action, row) {
      this.openModal(action, [row])
    },
    openModal(action, contacts) {
      this.$store.commit('dashboardOpenModal', {
        contactAction: action,
        contactsSelected: contacts.map((row) => ({
          custom_inputs: [],
          ...row,
        })),
      })
    },
    closeModal() {
      this.$store.commit('dashboardCloseModal')
    },
    updateContactFromIdentityModal({ updatedIdentity, modalConfig }) {
      const editedContact = modalConfig.editedSignIdentity || {}
      const request = {
        workspace_id: this.$route.params.workspace_id,
        action: 'upsertContacts',
        contacts: [
          {
            id: editedContact ? editedContact.id : null,
            email: updatedIdentity.email,
            mobile: updatedIdentity.mobile,
            firstname: updatedIdentity.firstname,
            lastname: updatedIdentity.lastname,
            birth_date: updatedIdentity.number,
            organization_name: updatedIdentity.organization_name,
            organization_city: updatedIdentity.organization_city,
            organization_street: updatedIdentity.organization_street,
            organization_zip: updatedIdentity.organization_zip,
            organization_ico: updatedIdentity.organization_ico,
            organization_dic: updatedIdentity.organization_dic,
            signatureFooter: updatedIdentity.signatureFooter,
          },
        ],
      }
      ContactService.manageContacts(request).then(
        () => {
          this.reloadDataAndCloseModal()
        },
        () => {
          this.$notification.error(this.$t('contract.errors.general_save'))
        },
      )
    },
    sendDocumentToContact() {
      this.$router.push({
        name: 'createNew',
        params: {
          workspace_id: this.$route.params.workspace_id,
        },
        query: {
          contacts: this.contact.id,
        },
      })
    },
    async reloadDataAndCloseModal() {
      this.closeModal()
      await this.fetchData()
    },
    cancelContactVerification() {
      this.cancelingVerification = true

      cancelVerification({
        contactId: this.contact.id,
        verificationTypes: {
          types: ['bankId', 'document', 'microTransaction', 'payment', 'political'],
        },
      })
        .then(() => {
          this.fetchData().then(() => {
            this.$notification.success(this.$t('verification.cancel.success'))
          })
        })
        .catch(() => {
          this.$notification.error(this.$t('verification.cancel.error'))
        })
        .finally(() => {
          this.cancelingVerification = false
          this.dialog = false
        })
    },
  },
})
</script>

<style lang="scss" scoped>
.contacts-detail__back-button {
  margin-top: -60px;
  color: #090909;
  margin-bottom: 20px;

  a {
    font-weight: normal;
    text-decoration: none;
  }

  svg {
    position: relative;
    top: 3px;
    color: #5d2a9c;
    width: 18px;
    height: auto;
    margin-right: 8px;
  }

  @include md {
    margin-top: 0;
  }
}

.contacts-detail {
  border: 1px solid #e0e3e8;
  background: #ffffff;
  border-radius: 6px;
  width: 100%;

  .contacts-detail__head-wrapper {
    border-bottom: 1px solid #e0e3e8;

    .contacts-detail__head-info {
      flex: 95%;
      padding: {
        top: 20px;
        right: 0;
        bottom: 20px;
        left: 0;
      }

      &-name {
        h2 {
          margin-bottom: 5px;
        }
      }

      .contacts-detail__head-info-groups {
        &-item {
          margin-right: 3px;
          border: 1px solid #82e2b2;
          border-radius: 6px;
          font-size: small;
          color: #82e2b2;
          padding: 5px;
          float: left;

          &-edit {
            @extend .contacts-detail__head-info-groups-item;
            color: #7f8287;
            border: unset;
            border-radius: unset;
            font-weight: initial;
            font-size: initial;
          }
        }
      }
    }

    @include md {
      margin: {
        top: 0;
        right: 20px;
        bottom: 0;
        left: 20px;
      }

      .contacts-detail__head-info-groups-item {
        padding: 3px !important;
        margin: 3px;
      }
    }
  }

  .contacts-detail__wrapper {
    display: flex;
    flex-wrap: wrap;

    &-item {
      flex: 50%;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: {
        top: 10px;
        right: 30px;
        bottom: 20px;
        left: 30px;
      }

      .contacts-detail__documents-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;

        &-item {
          flex: 50%;

          @include md {
            flex: 100%;
            margin-left: 0;
          }
        }

        .button {
          color: #5e239e;
          border: 1px solid #5e239e;
          background: unset;
          height: 40px;
          max-width: 160px;
          font-weight: bold;
          font-size: initial;
        }
      }

      .contacts-detail__documents {
        height: 450px;
        min-height: 450px;
        overflow: hidden;

        @include md {
          height: 300px;
          min-height: 300px;
        }

        .contacts-detail__documents-document {
          display: flex;

          &-column {
            padding: 10px;
            flex: 95%;

            &:only-child {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .contacts-detail__title {
              word-break: break-all;

              h3 {
                color: #000000;
                font-size: initial;
                font-weight: initial;
                padding-bottom: 10px;
              }
            }

            &-row {
              color: #858c99;
              padding-bottom: 10px;
              font-size: small;
            }
          }

          &-column:first-child {
            flex: 5%;

            svg {
              width: 20px;
              height: auto;
              padding-top: 0 !important;
            }
          }

          border: {
            right: 1px solid #e0e3e8;
            left: 1px solid #e0e3e8;
            bottom: 1px solid #e0e3e8;
          }

          &:first-child {
            border-top: 1px solid #e0e3e8;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }

          &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
      .contacts-detail__documents-full {
        height: unset;
        overflow: unset;
      }

      .contacts-detail__documents-show-all-button {
        margin-top: 20px;
        text-align: center;

        a {
          color: #7f8287;
          font-size: initial;
          font-weight: initial;
        }
      }

      .contacts-detail__documents-empty {
        text-align: center;
        padding: 100px 20%;

        p {
          padding-top: 15px;
          padding-bottom: 15px;
          font-weight: normal;
          color: #787c81;
        }

        .button {
          color: #5e239e;
          border: 1px solid #5e239e;
          background: unset;
          width: unset;
          padding-right: 20px;
          padding-left: 20px;
          height: 45px;
        }

        @include md {
          padding: 30px 20%;
        }
      }

      .contacts-detail__contact-head {
        display: grid;
        grid-auto-flow: column;

        a {
          color: #787c81;
          font-weight: unset;
        }

        @include md {
          a {
            text-align: right;
          }
        }
      }

      .contacts-detail__contact-row {
        display: flex;

        & > div {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        &-item-left {
          flex: 30%;
          color: #787c81;
          font-size: medium;
        }

        &-item-right {
          flex: 70%;
          color: black;
          font-size: medium;
        }

        .phone,
        .email {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          a {
            color: #6229a1;
          }
        }
      }
    }

    &-item:first-child {
      border-right: 1px solid #e0e3e8;
    }

    @include md {
      flex-direction: column;

      &-item {
        flex: 100%;
        margin: {
          top: 5px;
          left: 20px;
          right: 20px;
          bottom: 0;
        }
        padding: {
          top: 20px;
          right: 0;
          bottom: 20px;
          left: 0;
        }
      }

      &-item:first-child {
        border-right: unset;
        border-bottom: 1px solid #e0e3e8;
      }
    }
  }
}

.contacts-detail__document-overflow {
  position: relative;
  width: 100%;
  min-height: 30px;
  margin-top: -30px;
  background-image: linear-gradient(to bottom, transparent, #ffffff);
}

.contacts-modal {
  padding: 0 20%;

  &__actions {
    display: flex;

    & > .button {
      margin: 10px;
    }

    .button {
      flex: 70%;
    }

    .button:first-child {
      background: unset;
      border: 1px solid #979797;
      color: #6730a4;
      flex: 30%;
    }
  }

  @include md {
    padding: 0;
  }
}
</style>
